import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("rect", {
      width: "23",
      height: "23",
      x: ".5",
      y: ".5",
      fill: "var(--icon-check-bg, #548E74)",
      rx: "11.5"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "m10 14.78-2.314-2.313a.664.664 0 1 0-.94.94l2.786 2.786c.26.26.68.26.94 0l7.054-7.053a.664.664 0 1 0-.94-.94l-6.587 6.58Z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }